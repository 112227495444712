.homepage-bg {
  height: 100vh;
  margin: 0;
  --x: 0px;
  --y: 0px;

  background-image: 
    linear-gradient(115deg, rgba(0, 0, 0, 1), rgba(20, 0, 40, 0.8)), /* Black with a subtle purple tone */
    radial-gradient(90% 100% at calc(50% + var(--x)) calc(0% + var(--y)), rgba(50, 0, 100, 0.5), rgba(0, 20, 40, 0.4)), /* Black with soft purple */
    radial-gradient(100% 100% at calc(80% - var(--x)) calc(0% - var(--y)), rgba(0, 50, 100, 0.5), rgba(0, 0, 0, 0.8)), /* Cyan accent fading into black */
    radial-gradient(150% 210% at calc(100% + var(--x)) calc(0% + var(--y)), rgba(20, 0, 60, 0.7), rgba(0, 0, 0, 0.9)), /* Subtle purple fading into black */
    radial-gradient(100% 100% at calc(100% - var(--x)) calc(30% - var(--y)), rgba(0, 50, 80, 0.5), rgba(0, 0, 0, 0.8)), /* Cyan accent with black fade */
    linear-gradient(60deg, rgba(0, 0, 0, 1), rgba(20, 0, 40, 0.7)); /* Black with a purple tone diagonally */
  
  background-blend-mode: overlay, overlay, difference, difference, difference, normal;
  
  animation: moveBackground 10s infinite linear;
}

@keyframes moveBackground {
  0% {
    --x: -100px;
    --y: -100px;
  }
  25% {
    --x: 100px;
    --y: -50px;
  }
  50% {
    --x: 50px;
    --y: 100px;
  }
  75% {
    --x: -50px;
    --y: 50px;
  }
  100% {
    --x: -100px;
    --y: -100px;
  }
}

