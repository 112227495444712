@import "https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
.word {
  transition: transform .5s ease-in-out;
}

.slide-in {
  transform: translateY(0);
}

.slide-out {
  transform: translateY(-100%);
}

.typewriter {
  white-space: nowrap;
  border-right: .4em solid #000;
  animation: 2s steps(20, end) typing, .35s step-end infinite blink-caret;
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from, to {
    border-color: #0000;
  }

  50% {
    border-color: #a600ff;
  }
}

.con {
  opacity: 1;
  background: none;
  width: 100vw;
  -webkit-mask-image: radial-gradient(circle, #0000 40%, #000 100%);
  mask-image: radial-gradient(circle, #0000 40%, #000 100%);
}

@keyframes slideInFromRight {
  from {
    filter: blur(.625rem);
    opacity: 0;
    transform: translateX(100%)rotate(10deg);
  }

  to {
    filter: blur();
    opacity: 1;
    transform: translateX(0)rotate(0);
  }
}

@keyframes slideOutToRight {
  from {
    opacity: 1;
    transform: translateX(0)rotate(0);
  }

  to {
    opacity: 0;
    transform: translateX(100%)rotate(-10deg);
  }
}

@keyframes slideUpFromBottom {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDownFromTop {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

.homepage-bg {
  --x: 0;
  --y: 0;
  background-image: linear-gradient(115deg, #000, #140028cc), radial-gradient(90% 100% at calc(50% + var(--x)) calc(0% + var(--y)), #32006480, #00142866), radial-gradient(100% 100% at calc(80% - var(--x)) calc(0% - var(--y)), #00326480, #000c), radial-gradient(150% 210% at calc(100% + var(--x)) calc(0% + var(--y)), #14003cb3, #000000e6), radial-gradient(100% 100% at calc(100% - var(--x)) calc(30% - var(--y)), #00325080, #000c), linear-gradient(60deg, #000, #140028b3);
  background-blend-mode: overlay, overlay, difference, difference, difference, normal;
  height: 100vh;
  margin: 0;
  animation: 10s linear infinite moveBackground;
}

@keyframes moveBackground {
  0% {
    --x: -6.25rem;
    --y: -6.25rem;
  }

  25% {
    --x: 6.25rem;
    --y: -3.125rem;
  }

  50% {
    --x: 3.125rem;
    --y: 6.25rem;
  }

  75% {
    --x: -3.125rem;
    --y: 3.125rem;
  }

  100% {
    --x: -6.25rem;
    --y: -6.25rem;
  }
}

.scale-in-hor-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-hor-left;
}

@keyframes scale-in-hor-left {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

.footer-link {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  margin-right: 3.5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.footer-link:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 49, 253, var(--tw-text-opacity));
  text-decoration: underline;
}

.footer-link {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (width >= 280px) and (width <= 599.98px) {
  .footer-link {
    margin-right: 0;
  }
}

@media (width >= 600px) and (width <= 1024px) {
  .footer-link {
    margin-right: 0;
  }
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  color: inherit;
  height: 0;
}

abbr[title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  font-family: inherit;
  line-height: inherit;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

hr {
  border-top-width: .0625rem;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.top-0 {
  top: 0;
}

.top-2 {
  top: .5rem;
}

.top-16 {
  top: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.left-0 {
  left: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.ml-12 {
  margin-left: 3rem;
}

.ml-20 {
  margin-left: 5rem;
}

.ml-24 {
  margin-left: 6rem;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-full {
  height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-10 {
  width: 2.5rem;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.flex-1 {
  flex: 1;
}

.origin-left {
  transform-origin: 0;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
}

.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

@keyframes pulseShadow {
  0% {
    box-shadow: 0 0 .1875rem .0625rem #ae2be2d6, 0 0 .9375rem .25rem #3700ffba;
  }

  50% {
    box-shadow: 0 0 .625rem .25rem #ae2be2d6, 0 0 1.25rem .5rem #3700ffba;
  }

  100% {
    box-shadow: 0 0 .1875rem .0625rem #ae2be2d6, 0 0 .9375rem .25rem #3700ffba;
  }
}

.animate-pulse-shadow {
  animation: 5s ease-in-out infinite pulseShadow;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.gap-14 {
  gap: 3.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.border {
  border-width: .0625rem;
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(146, 49, 253, var(--tw-border-opacity));
}

.hover\:border-hightGreenColor:hover {
  --tw-border-opacity: 1;
  border-color: rgba(206, 31, 222, var(--tw-border-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 49, 253, var(--tw-bg-opacity));
}

.bg-shadowColor {
  background-color: #210e21cc;
}

.bg-footerHoverColor {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 198, 162, var(--tw-bg-opacity));
}

.hover\:bg-hightGreenColor:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(206, 31, 222, var(--tw-bg-opacity));
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.bg-primary-gradient {
  background-image: linear-gradient(to right, #9231fd, #ce1fde);
}

.bg-secondary-gradient {
  background-image: linear-gradient(to bottom right, #1a052a, #011118);
}

.p-6 {
  padding: 1.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-13 {
  font-size: .8125rem;
}

.text-26 {
  font-size: 1.375rem;
}

.text-32 {
  font-size: 1.75rem;
}

.text-42 {
  font-size: 2.375rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-extrabold {
  font-weight: 800;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-primary, .text-mobile {
  --tw-text-opacity: 1;
  color: rgba(146, 49, 253, var(--tw-text-opacity));
}

.text-warningColor {
  --tw-text-opacity: 1;
  color: rgba(215, 63, 117, var(--tw-text-opacity));
}

.text-opacity80White {
  --tw-text-opacity: 1;
  color: rgba(204, 204, 204, var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 49, 253, var(--tw-text-opacity));
}

.hover\:text-hightGreenColor:hover {
  --tw-text-opacity: 1;
  color: rgba(206, 31, 222, var(--tw-text-opacity));
}

.text-opacity-50 {
  --tw-text-opacity: .5;
}

.opacity-50 {
  opacity: .5;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

*, :before, :after {
  --tw-shadow: 0 0 #0000;
}

.shadow-glow {
  --tw-shadow: 0 0 .3125rem .1875rem #8a2be299, 0 0 2.1875rem .9375rem #0ff6;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-glowx {
  --tw-shadow: 0 0 .3125rem .1875rem #ae2be2d6, 0 0 2.1875rem .9375rem #3700ffba;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-glowy {
  --tw-shadow: 0 0 .3125rem .1875rem #712be2d6, 0 0 2.1875rem .9375rem #0084ff6e;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-glow:hover {
  --tw-shadow: 0 0 .3125rem .1875rem #8a2be299, 0 0 2.1875rem .9375rem #0ff6;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-glowx:hover {
  --tw-shadow: 0 0 .3125rem .1875rem #ae2be2d6, 0 0 2.1875rem .9375rem #3700ffba;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-glowy:hover {
  --tw-shadow: 0 0 .3125rem .1875rem #712be2d6, 0 0 2.1875rem .9375rem #0084ff6e;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

*, :before, :after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.filter {
  --tw-blur: var(--tw-empty, );
  --tw-brightness: var(--tw-empty, );
  --tw-contrast: var(--tw-empty, );
  --tw-grayscale: var(--tw-empty, );
  --tw-hue-rotate: var(--tw-empty, );
  --tw-invert: var(--tw-empty, );
  --tw-saturate: var(--tw-empty, );
  --tw-sepia: var(--tw-empty, );
  --tw-drop-shadow: var(--tw-empty, );
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.blur {
  --tw-blur: blur(.5rem);
}

.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 1.5625rem 1.5625rem #00000026);
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-shadow {
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@font-face {
  font-family: gotham;
  src: url("Gotham-Book.66fd309d.ttf");
}

@font-face {
  font-family: gothamBold;
  src: url("Gotham-Bold.1693355d.otf");
}

@font-face {
  font-family: gothamLight;
  src: url("Gotham-Light.dfe24d2b.otf");
}

.gotham_font {
  font-family: gotham;
}

.gotham_font_bold {
  font-family: gothamBold;
}

.gotham_font_light {
  font-family: gothamLight;
}

.reactTip {
  opacity: 1 !important;
  border-radius: .375rem !important;
  min-width: 7rem !important;
  padding: .44rem .75rem !important;
}

.tooltip-content:after {
  content: " ";
  border: .625rem solid #0000;
  border-bottom-color: #222433;
  margin-left: -.625rem;
  position: absolute;
  bottom: 100%;
  left: 50%;
}

.tooltip {
  visibility: invisible;
}

.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 50;
}

.__react_component_tooltip:before, .__react_component_tooltip:after {
  content: none !important;
}

.__react_component_tooltip {
  z-index: 99999;
}

@media (width >= 280px) and (width <= 599.98px) {
  .sm\:relative {
    position: relative;
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:-mt-1 {
    margin-top: -.25rem;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:-mr-4 {
    margin-right: -1rem;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .sm\:mb-44 {
    margin-bottom: 11rem;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:origin-left {
    transform-origin: 0;
  }

  .sm\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 600px) and (width <= 1024px) {
  .md\:relative {
    position: relative;
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mt-52 {
    margin-top: 13rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:origin-left {
    transform-origin: 0;
  }

  .md\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .md\:scale-75 {
    --tw-scale-x: .75;
    --tw-scale-y: .75;
  }

  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:gap-6 {
    gap: 1.5rem;
  }

  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-26 {
    font-size: 1.375rem;
  }

  .md\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1025px) {
  .lg\:top-12 {
    top: 3rem;
  }

  .lg\:mr-28 {
    margin-right: 7rem;
  }

  .lg\:mr-36 {
    margin-right: 9rem;
  }

  .lg\:mr-64 {
    margin-right: 16rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:ml-36 {
    margin-left: 9rem;
  }

  .lg\:ml-64 {
    margin-left: 16rem;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }
}

@media (width >= 280px) and (width <= 1024px) {
  .s\:mt-4 {
    margin-top: 1rem;
  }
}

@media (width >= 1024px) {
  .l\:mt-6 {
    margin-top: 1.5rem;
  }
}

/*# sourceMappingURL=index.7607cb89.css.map */
