.word {
  transition: transform 0.5s ease-in-out;
}

.slide-in {
  transform: translateY(0);
}

.slide-out {
  transform: translateY(-100%);
}

.typewriter {
  border-right: 0.4em solid rgb(0, 0, 0); /* The typewriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  animation: typing 2s steps(20, end) 1, blink-caret 0.35s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(166, 0, 255);
  }
}