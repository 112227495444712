@keyframes slideInFromRight {
  from {
    transform: translateX(100%) rotate(10deg); /* Starting off-screen with rotation */
    filter: blur(10px);
    opacity: 0;
  }
  to {
    transform: translateX(0) rotate(0deg); /* End at the original position with no rotation */
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes slideOutToRight {
  from {
    transform: translateX(0) rotate(0deg); /* Starting from the current position with no rotation */
    opacity: 1;
  }
  to {
    transform: translateX(100%) rotate(-10deg); /* Move off-screen with rotation */
    opacity: 0;
  }
}

@keyframes slideUpFromBottom {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDownFromTop {
  from {
      transform: translateY(0);
      opacity: 1;
  }

  to {
      transform: translateY(-100%);
      opacity: 0;
  }
}